export default [
    {
        key: 'code',
        label: 'field.sectionUsedCode',
        rules: 'required|max:10',
        type: 'text'
    },
    {
        key: 'name',
        label: 'field.sectionUsedName',
        rules: 'required|max:100',
        type: 'text'
    },
    {
        key: 'isEnable',
        label: 'status.active',
        rules: '',
        type: 'checkbox',
        cols: 'auto',
    },
  ]
  